@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&family=Catamaran:wght@400;700&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif!important;
  font-size: 0.8em!important;
  line-height: 1.3em;
  font-weight: 200;
  color: #645D85;
  
  /* background-color:#F6F5FA;  */
  /* background: linear-gradient(90deg,#F6F5FA,#EFF2F7)!important; */
  background: linear-gradient(90deg,#e3e3da,#c5d7da)!important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong, b {
  font-weight: 600!important;
}

h1,h2,h3,h4,h5,h6, .h1, .h2, .h3, .h4, .h5, .h6, .btn {
  font-family: "Catamaran"!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h5,.h5 {
  font-weight: 700!important;
  color: #4385e0!important;
}

h4, .h4 {
  font-weight: 700!important;
  font-size: 1em!important;
}

.bg-image {
  z-index: -1;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  filter: blur(48px);
  -webkit-filter: blur(48px);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .3;
}

.bg-purple {
  background-color: #373656!important;
}

.panel {
  font-size: 14px;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
}

.panel .form-control, .panel select, .panel .form-check-label {
  font-size: 13px!important;
  color: #373656!important;
}

.panel .form-label {
  /* margin: 0px; padding: 0px; */
  /* padding-left: 4px; */
  font-weight: 500;
  /* margin-top: 5px; */
}

.invert {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.small {
  font-size: 12px!important;
}

.banner {
  /* position: absolute; */
  background-image: url("assets/bg.avif");
  z-index: -1;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    filter: blur(18px);
    -webkit-filter: blur(18px);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .3;
  /* background-size: cover;
  background-color: white;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index:-1;
  background-position: bottom center;
  background-repeat: no-repeat;
  filter: blur(18px);
  -webkit-filter: blur(18px); */
}

.footer-section a {
  color: #fff!important;
}

a {
  color: #000!important;
  text-decoration: underline;
}